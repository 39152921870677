import React, { useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

import { Button, ErrorBanner, Public } from "../../ui";
import { useAuth } from "../../auth";

type Props = {
  className?: string;
  nextStep: any;
};

type Inputs = {
  password: string;
  confirmPassword: string;
};

const newPasswordOpts = {
  required: "Password must be at least 6 characters long.",
  minLength: {
    value: 6,
    message: "Password must be at least 6 characters long.",
  },
};

const confirmPasswordOpts = {
  required: "Confirm Password must be at least 6 characters long.",
  minLength: {
    value: 6,
    message: "Confirm Password must be at least 6 characters long.",
  },
};

const ResetPassword = ({ nextStep }: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<Inputs>();
  const [formError, setFormError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const router = useRouter();
  const { login } = useAuth();

  const onSubmit = async ({ password, confirmPassword }, e) => {
    e.preventDefault();
    setFormError(null);
    setIsSending(true);
    if (confirmPassword !== password) {
      return setFormError("The password and confirmation password do not match.");
    }
    try {
      const session = await Auth.completeNewPassword(nextStep, password);
      if (!session.challengeName) {
        try {
          const authenticatedUser = await Auth.currentAuthenticatedUser()
          login(authenticatedUser);
          return router.push("/templates");
        } catch (error) {
          setFormError(error?.message || "An unknown error occurred on login");
        }
      }
    } catch (error) {
      setFormError(error?.message || "An unknown error occurred on login");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Public.Body title="Reset password">
      <form className="space-y-6 mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            New Password
          </label>
          <div className="mt-1">
            <input
              name="password"
              type="password"
              id="password"
              ref={register(newPasswordOpts)}
              autoComplete="current-password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
            />
          </div>
          {errors?.password && <ErrorBanner title={errors.password.message} />}
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Confirm Password
          </label>
          <div className="mt-1">
            <input
              name="confirmPassword"
              type="password"
              id="confirmPassword"
              ref={register(confirmPasswordOpts)}
              autoComplete="current-password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
            />
          </div>
          {errors?.confirmPassword && (
            <ErrorBanner title={errors?.confirmPassword.message} />
          )}
        </div>

        {formError && <ErrorBanner title={formError} />}
        <div>
          <Button
            tag="button"
            type="submit"
            disabled={isSubmitting || isSending}
            className="w-full flex justify-center"
          >
            {(isSubmitting || isSending) ? "Submitting" : "Submit"}
          </Button>
        </div>
      </form>
    </Public.Body>
  );
};

export default ResetPassword;
